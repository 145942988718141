import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import { Slices } from '../slices/slices';
import './page.scss';

export const Page = props => {
  const { location, data } = props;
  const { site } = data;
  const { siteName } = site.siteMetadata;
  const dataSlices = data && data.prismicPage && data.prismicPage.data && data.prismicPage.data.body;
  const dataPage = data && data.prismicPage && data.prismicPage.data;

  return (
    <Layout location={location}>
      <SEO title={`${dataPage && dataPage.title && dataPage.title.text} | ${siteName}`} />
      <div className="page">
        <Slices data={dataSlices} />
      </div>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query($uid: String!) {
    site {
      siteMetadata {
        siteName
      }
    }
    prismicPage(uid: { eq: $uid }) {
      data {
        title: page_title {
          text
        }
        description: page_description {
          text
        }
        body {
          ...PageAccordion
          ...PageFormLong
          ...PageFormShort
          ...PageGrid
          ...PageImageFeatures
          ...PageImageText
          ...PagePartners
          ...PageSlider
          ...PageFeaturedProducts
          ...PageSuppliers
        }
      }
    }
  }
`;
